<template>
  <div class="select-outer">
    <van-nav-bar
      title="券品"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="filter-top">
     <div class="search-block">
       <van-search
        v-model="valueSearch"
        placeholder="请输入券名称"
        background="#FFF"
        shape="round"
        class="search"
        @search="onSearch"
      />
     </div>
     <div class="type-btns">
      
     </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueSearch:""
    };
  },
  methods: {
    onSearch(value) {},
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>

.select-outer{
   /deep/ .van-nav-bar .van-icon {
    color: #333;
  }
  /deep/ .van-nav-bar__text {
    color: #333;
  }
   /deep/ .van-search {
    padding: 0;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  .filter-top{
    display: flex;
    justify-content: space-between;
    .search-block{
      width: 50%;
    }
  }
}
</style>
